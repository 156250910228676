
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.escalation {
  padding: 24px;
  border-radius: 12px;
  border: 1px rgba(232, 232, 238, 1) solid;
  background: #fff;
  &__field {
    margin: 20px 0 0 0;
    &-label {
      font-weight: bold;
      font-size: 14px;
    }
    &-value {
      margin: 4px 0 0 0;
      font-size: 14px;
    }
  }
}
